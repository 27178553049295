<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_27883_48712)">
      <path d="M27.5391 13.7188L13.3836 27.8742" stroke="white" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round"/>
      <path d="M13.3828 13.7188L27.5382 27.8742" stroke="white" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_27883_48712">
        <rect width="39" height="39" fill="white" transform="translate(0.5 0.5)"/>
      </clipPath>
    </defs>
  </svg>
</template>
