<template>
  <header>
    <div class="wrapper wrapper_padding wrapper_header">
      <!--  DESKTOP MENU  -->
      <div class="header">

        <div class="header__logo">
          <OfferLogo/>
        </div>

        <div class="header__nav-container">
          <nav>
            <ul class="header__nav bloc-blur-bordered">
              <li class="header__nav__item" @click="navClickHandler('#gallery')">{{ $t('offer_new_nav1') }}</li>
              <li class="header__nav__item" @click="navClickHandler('#examples')">{{ $t('offer_new_nav2') }}</li>
              <li class="header__nav__item" @click="navClickHandler('#advantages')">{{ $t('offer_new_nav3') }}</li>
              <li class="header__nav__item" @click="navClickHandler('#steps')">{{ $t('offer_new_nav4') }}</li>
              <li class="header__nav__item" @click="navClickHandler('#tariff')">{{ $t('offer_new_nav5') }}</li>
            </ul>
          </nav>

          <button @click="changeLang('en')" v-if="locale === 'ru' && !isDflat" class="translate bloc-blur-bordered">
            <OfferSvgRu/>
          </button>
          <button @click="changeLang('ru')" v-else-if="!isDflat" class="translate bloc-blur-bordered">
            <OfferSvgEn/>
          </button>

          <div class="header__option">
            <button @click="navClickHandler('#tariff')" class="button header__option__button_1 button_white-bordered">
              {{ $t('offer_new_download') }}
            </button>
            <button @click="menuActive = true" class="button header__option__button_2 button_white-bordered">
              {{ $t('offer_new_menu') }}
            </button>
            <svg @click="menuActive = true" class="header__option__button_3" width="29" height="27" viewBox="0 0 29 27"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1" y="9.21094" width="28" height="1.5" rx="0.75" fill="white"/>
              <rect x="1" y="16.2109" width="28" height="1.5" rx="0.75" fill="white"/>
            </svg>
          </div>
        </div>

      </div>
    </div>

    <!--  DROP MENU  -->
    <div class="header__drop-menu" :class="{'header__drop-menu_active' : menuActive}">

      <OfferLogo :white="true"/>

      <nav>
        <ul class="header__nav header__nav_mob">
          <li class="header__nav__item" @click="navClickHandler('#gallery')">{{ $t('offer_new_nav1') }}</li>
          <li class="header__nav__item" @click="navClickHandler('#examples')">{{ $t('offer_new_nav2') }}</li>
          <li class="header__nav__item" @click="navClickHandler('#advantages')">{{ $t('offer_new_nav3') }}</li>
          <li class="header__nav__item" @click="navClickHandler('#steps')">{{ $t('offer_new_nav4') }}</li>
          <li class="header__nav__item" @click="navClickHandler('#tariff')">{{ $t('offer_new_nav5') }}</li>
        </ul>
      </nav>

      <div class="header__drop-menu__buttons">
        <div class="header__drop-menu__buttons__right">
          <button @click="changeLang('en')" v-if="locale === 'ru' && !isDflat" class="translate bloc-blur-bordered">
            <OfferSvgRu/>
          </button>
          <button @click="changeLang('ru')" v-else-if="!isDflat" class="translate bloc-blur-bordered">
            <OfferSvgEn/>
          </button>
          <button @click="menuActive = false" class="translate bloc-blur-bordered" style="border-color: white">
            <OfferSvgClose/>
          </button>
        </div>

        <button @click="navClickHandler('#tariff')" class="button button_white-bordered">
          {{ $t('offer_new_download_free') }}
        </button>
      </div>

    </div>

    <!--  MOB RIGHT  -->
    <div class="header__drop-menu-right" :class="{'header__drop-menu-right_active' : menuActive}">

      <div class="flex justify-between">

        <OfferLogo :white="true"/>

        <div class="flex gap-10">
          <button @click="changeLang('en')" v-if="locale === 'ru' && !isDflat" class="translate bloc-blur-bordered">
            <OfferSvgRu/>
          </button>
          <button @click="changeLang('ru')" v-else-if="!isDflat" class="translate bloc-blur-bordered">
            <OfferSvgEn/>
          </button>
          <button @click="menuActive = false" class="translate bloc-blur-bordered" style="border-color: white">
            <OfferSvgClose/>
          </button>
        </div>
      </div>

      <nav>
        <ul class="header__nav header__nav_mob">
          <li class="header__nav__item header__nav__title">{{ $t('offer_new_menu') }}</li>
          <li class="header__nav__item header__nav__text" @click="navClickHandler('#gallery')">{{
              $t('offer_new_nav1')
            }}
          </li>
          <li class="header__nav__item header__nav__text" @click="navClickHandler('#examples')">{{
              $t('offer_new_nav2')
            }}
          </li>
          <li class="header__nav__item header__nav__text" @click="navClickHandler('#advantages')">
            {{ $t('offer_new_nav3') }}
          </li>
          <li class="header__nav__item header__nav__text" @click="navClickHandler('#steps')">{{
              $t('offer_new_nav4')
            }}
          </li>
          <li class="header__nav__item header__nav__text" @click="navClickHandler('#tariff')">{{
              $t('offer_new_nav5')
            }}
          </li>
        </ul>
      </nav>

      <ul class="header__nav header__nav_mob">
        <li class="header__nav__item header__nav__title">{{ $t('offer_new_contacts') }}</li>
        <li class="header__nav__item header__nav__text" style="word-break: break-word">
          <a href="mailto:support@dreamflat.design">support@dreamflat.design</a>
        </li>
        <a href="https://www.linkedin.com/company/93650643/">
          <svg width="69" height="70" viewBox="0 0 69 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M45.8016 23.4609C50.3571 23.4609 54.726 25.2706 57.9473 28.4918C61.1685 31.7131 62.9782 36.082 62.9782 40.6375V60.6769H51.5271V40.6375C51.5271 39.119 50.9239 37.6627 49.8501 36.589C48.7764 35.5152 47.3201 34.912 45.8016 34.912C44.2831 34.912 42.8268 35.5152 41.753 36.589C40.6793 37.6627 40.0761 39.119 40.0761 40.6375V60.6769H28.625V40.6375C28.625 36.082 30.4347 31.7131 33.6559 28.4918C36.8771 25.2706 41.2461 23.4609 45.8016 23.4609Z"
              stroke="white" stroke-width="1.29286" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.1776 26.3281H5.72656V60.6813H17.1776V26.3281Z" stroke="white" stroke-width="1.29286"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path
              d="M11.4521 17.7323C14.6142 17.7323 17.1776 15.1689 17.1776 12.0068C17.1776 8.84466 14.6142 6.28125 11.4521 6.28125C8.28997 6.28125 5.72656 8.84466 5.72656 12.0068C5.72656 15.1689 8.28997 17.7323 11.4521 17.7323Z"
              stroke="white" stroke-width="1.29286" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
      </ul>

      <button @click="navClickHandler('#tariff')" class="button button_white-black-bordered button_fill-mob">
        {{ $t('offer_new_see') }}
      </button>

    </div>
  </header>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import {goTo} from '~/helpers/baseFunction';

/* RU ENG */
const runtimeConfig = useRuntimeConfig();
const isDflat = runtimeConfig.public.vueAppBaseUrl.includes('d-flat.ru');

/* MENU */
const menuActive = ref(false);

/* TRANSLATION */
const {locale} = useI18n();
const changeLang = (lang) => {
  locale.value = lang;
}

/* NAVIGATION */
const navClickHandler = (id) => {
  menuActive.value = false;
  goTo(id);
}

</script>

<style scoped lang="scss">
@import "css/offer/offer.scss";

.gap-10 {
  gap: 10px;
}

.wrapper_header {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
}

.header {
  padding-top: 30px;

  height: 72px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__nav-container {
  display: flex;
  align-items: center;
  gap: 18px;
}

.header__nav {
  box-sizing: border-box;
  padding: 18px 44px;

  display: flex;
  align-items: center;
  gap: 2.5vw;

  list-style: none;

  @include desktop-l {
    display: none;
  }
}

.header__nav_mob {
  color: $colorTextWhite;

  padding: 20px 0 30px 20px;

  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: left;
  align-items: flex-start;

  @include tablet {
    gap: 25px;
    padding-top: 40px;
  }
}

.header__nav__item {
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  text-align: left;
  color: $colorTextWhite;
  cursor: pointer;
}

.header__option__button_1 {
  display: block;

  @include desktop-l {
    display: none;
  }
}

.header__option__button_2 {
  display: none;

  @include desktop-l {
    display: block;
  }

  @include tablet-s {
    display: none;
  }
}

.header__option__button_3 {
  display: none;

  @include tablet-s {
    display: block;
  }
}

/* Translate */
.translate {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 64px;
  height: 64px;
  border-radius: 50%;
  flex-shrink: 0;

  cursor: pointer;
  transition: 250ms;

  &:hover {
    border-color: $colorTextWhite;
    background: #FFFFFF66;
    transition: 250ms;
  }

  &:focus {
    border-color: $colorTextWhite;
  }

  @include tablet-s {
    border: none;
    background: none;
    backdrop-filter: none;
    width: auto;
  }
}

/* Drop menu */
.header__drop-menu {
  position: fixed;

  height: 325px;
  width: 100%;

  box-sizing: border-box;
  padding: 25px 35px;


  top: -325px;
  z-index: 1000;
  right: 0;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  background: #040404;
  border-radius: 0 0 40px 40px;
  transition: 150ms;

  @include tablet {
    display: none;
  }
}

.header__drop-menu_active {
  top: 0;

  transition: 150ms;
}

.header__drop-menu__buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.header__drop-menu__buttons__right {
  display: flex;
  align-self: flex-end;
  gap: 18px;
}

.header__drop-menu-right {
  display: none;
  position: fixed;

  overflow-y: scroll;
  overflow-x: hidden;

  height: 100%;
  width: 100vw;

  box-sizing: border-box;
  padding: 25px 35px;

  right: -100vw;
  z-index: 1000;
  top: 0;

  background: #040404;
  transition: 150ms;

  @include tablet {
    display: block;
  }
}

.header__drop-menu-right_active {
  right: 0;
  background: #0f0f0f;
}

.header__nav__title {
  margin-bottom: 20px;

  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0.02em;
  text-align: left;
}

.header__nav__text {
  font-size: 28px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.02em;
  text-align: left;
}

/* Mobile */
.header__nav-container-mob {
  display: none;

  @include tablet-s {
    display: block;
  }
}

</style>
