<template>
  <OfferHeader/>
  <main class="main">
    <slot>

    </slot>
  </main>
  <OfferFooter/>
</template>

<script>

</script>

<style scoped lang="scss">
@import "css/offer/offer.scss";

</style>
